import React, { useState } from "react";
import styled from "styled-components";
import HubspotForm from "react-hubspot-form";

import ContactInfo from "@components/contact/contactInfo";
import { Container, H2, P } from "@util/standard";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import SEO from "@components/shared/seo";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 100%;
`;

const FormContainer = styled(Container)`
  flex-direction: column;
  width: 70%;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 90%;
  }
`;

export default function RequestAudit() {
  const [submitted, setSubmitted] = useState(false);

  const SuccessMessage = () => (
    <Container autoWidthNoMargin flexDirection="column">
      <H2>Thanks for your request</H2>
      <P>
        One of our experts will work on your request and get back to you as soon
        as possible. In the meantime, feel free to scroll down and meet the
        Flight Digital team, and be sure to check out our News & Blogs which
        have plenty of free downloadable resources.
      </P>
    </Container>
  );

  return (
    <Wrapper>
      <SEO slug="request-audit" />
      <ContactInfo pageTitle="Request Audit" />
      <FormContainer>
        {submitted ? (
          <SuccessMessage />
        ) : (
          <HubspotForm
            portalId="5521628"
            formId="61a785a7-e854-4ccf-986a-6c746bd0010b"
            onSubmit={() => setSubmitted(true)}
            loading={<div>Loading...</div>}
          />
        )}
      </FormContainer>
    </Wrapper>
  );
}
